<template>
<div>
  {{ msg }}
</div>
</template>

<script>
export default {
  name: 'Ping',
  data () {
    return {
      msg: 'Pong!'
    }
  }
}
</script>

<style scoped>

</style>
